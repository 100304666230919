import React from "react";
import { graphql } from "gatsby";
import Page from "../sb-components/page";
import Layout from "../sb-components/layout";
import StoryblokService from "../utils/storyblok-service";
import { PageStoryblok } from "../types";
import { PagePropsType } from ".";

type Story = {
  content: PageStoryblok;
};

type State = {
  story: Story;
};

class EmployerPage extends React.Component<PagePropsType, State> {
  constructor(props: PagePropsType) {
    super(props);
    this.state = {
      story: {
        content: this.props.data?.story
          ? JSON.parse(this.props.data.story.content)
          : {},
      },
    };
  }

  async componentDidMount() {
    const story = await this.getInitialStory();
    if (story.content) {
      this.setState({ story });
    }
    setTimeout(() => StoryblokService.initEditor(this), 200);
  }

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search);
    const {
      data: { story },
    } = await StoryblokService.get(
      `cdn/stories/${this.props.data.story.full_slug}`
    );
    return story;
  }

  render() {
    return (
      <Layout
        className="page page-landing-employer"
        full_slug={this.props.data.story.full_slug}
      >
        <Page
          blok={{
            ...this.state.story.content,
            title: this.state.story.content || "title",
          }}
        />
      </Layout>
    );
  }
}

export default EmployerPage;

export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "employer" }) {
      name
      content
      full_slug
      uuid
    }
  }
`;
